.address-fetcher__results-frame {
  height: 150px;
  background-color: var(--ion-color-light-tint);
  margin: 0.5rem 0;
  border-radius: 0.5rem;
  overflow-y: scroll;
  padding: 0 0.5rem;
  text-align: left;
  position: relative;
  overflow-x: hidden;
}
.address-fetcher__results-frame--extended {
  height: 250px;
}
.address-fetcher__results-frame__result {
  background-color: var(--ion-background-color);
  margin: 0.5rem 0;
  padding: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 0.5rem);
  cursor: pointer;
  transition: all 100ms ease-in-out;
}
.address-fetcher__results-frame__result:hover {
  filter: brightness(0.97);
}
.address-fetcher__results-frame__result--is-active {
  color: white;
  background-color: var(--ion-color-primary);
  text-align: left;
}

.address-fetcher__results-frame__loading {
  color: var(--ion-color-primary);
  font-weight: bold;
  text-align: center;
  width: 100%;
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: 500ms ease-in-out infinite alternate breath;
}
.address-fetcher__results-frame__no-results {
  font-weight: bold;
  text-align: center;
  width: 100%;
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes breath {
  0% {
    filter: brightness(0.9);
  }
  50% {
    filter: brightness(1.1);
  }
  100% {
    filter: brightness(0.9);
  }
}
