.start-layout__main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.start-layout__main__content {
  width: 100%;
  height: 100%;
  max-width: 24rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
