.category-edition-modal {
  padding: 1rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.category-edition-modal__delete-button {
  margin: 1rem 0;
}
