.products-options-item {
  background-color: white;
  padding: 14px 14px;
  border-radius: 8px;
  color: var(--ion-color-secondary);
  border: 2px solid transparent;
  transition: all 250ms ease;
  margin-bottom: 8px;
  box-shadow: var(--base-card-shadow);
  position: relative;
  cursor: pointer;
}

.products-options-item--selected {
  border: 2px solid var(--ion-color-primary);
}

.products-options-item__order-index {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: var(--ion-color-primary);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.products-options-item__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.products-options-item__header span:first-of-type {
  font-size: 18px;
  font-weight: bold;
}

.products-options-item__header span:last-of-type {
  font-style: italic;
}

.products-options-item__items-list {
  display: flex;
  flex-direction: column;
}

.products-options-item__items-list_item {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  opacity: 0.7;
  font-size: 14px;
}

.products-options-item__items-list_item__name {
  display: flex;
  align-items: center;
}

.products-options-item__items-list_item__name__dot {
  height: 10px;
  width: 10px;
  background-color: var(--ion-color-secondary);
  border-radius: 100%;
  margin-right: 10px;
}

.products-options-item__action {
  margin-top: 16px;
}
