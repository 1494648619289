.delivery-delay-selector {
  display: flex;
  flex-direction: column;
}
.delivery-delay-selector span:first-of-type {
  font-size: 12px;
  margin-bottom: 2px;
  font-weight: bold;
}
.delivery-delay-selector__delivery-time-updater {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
  border: 1px solid grey;
  font-size: 18px;
  border-radius: 8px;
}
.delivery-delay-selector__delivery-time-updater__diminish {
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ion-color-primary);
  color: white;
  font-weight: bold;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  cursor: pointer;
}
.delivery-delay-selector__delivery-time-updater__raise {
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ion-color-primary);
  color: white;
  font-weight: bold;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  cursor: pointer;
}
