.restaurant-infos-editor {
  display: grid;
  gap: 1rem;
  grid-template-areas:
    "restaurant-address"
    "restaurant-logo"
    "restaurant-base-infos"
    "restaurant-pixel-id"
    "restaurant-review-link"
    "restaurant-business-hours";
}
.restaurant-infos-editor__logo-input {
  display: flex;
  justify-content: space-between;
}
.restaurant-infos-editor__logo-input__logo-display {
  height: 130px;
  min-width: 130px;
  margin-right: 1rem;
  background-color: rgb(238, 238, 238);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}

.restaurant-infos-editor__url-label {
  display: flex;
  max-width: 100%;
  font-size: 0.9rem;
  white-space: nowrap;
  padding-bottom: 1rem;
}
.restaurant-infos-editor__base-text-input-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.restaurant-infos-editor__base-text-input__input {
  padding: 0.75rem 0.5rem;
  border: none;
  -webkit-appearance: none;
}

@media screen and (min-width: 900px) {
  .restaurant-infos-editor {
    display: grid;
    gap: 1rem;
    grid-template-areas:
      "restaurant-address restaurant-logo"
      "restaurant-address restaurant-base-infos"
      "restaurant-business-hours restaurant-business-hours"
      "restaurant-pixel-id restaurant-pixel-id"
      "restaurant-review-link restaurant-review-link";
    /* grid-template-columns:1fr 1fr; */
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
}
