.orders-planned-order-type-selector {
    width: 100%;
    margin: auto;
    max-width: 1440px;
    font-size: 1.125rem;
    font-weight: bold;
    color: var(--ion-color-secondary);
  }
  .orders-planned-order-type-selector__selector {
    cursor: pointer;
    opacity: 1;
    transition: opacity 250ms ease;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    padding: 1rem;
    border-radius: 2rem 4px 2rem 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 180px;
    justify-content: center;
    text-align: center;
    margin: 1rem 0;
  }
  .orders-planned-order-type-selector__selector__icon {
    min-height: 56px;
    width: 96px;
    display: flex;
    justify-content: center;
    margin-bottom: 0.5rem;
    font-size: 3rem;
  }
  
  .orders-planned-order-type-selector__selector__label {
    color: var(--ion-color-secondary);
    font-weight: 700;
    font-size: 1.125rem;
  }
  .orders-planned-order-type-selector__selector--active {
    background-color: var(--ion-color-primary);
    color: white;
  }
  .orders-planned-order-type-selector__selector--active
    .orders-planned-order-type-selector__selector__label {
    color: white;
  }
  