.category-chip {
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  border-radius: 8px;
  transition: all 150ms ease-in-out;
  cursor: pointer;
  white-space: nowrap;
}

.category-chip--muted {
  opacity: 0.3;
}
.category-chip--editor-mode {
  justify-content: space-between;
}
.category-chip__position-controls {
  font-size: 24px;
  display: flex;
}

.category-chip__position-controls {
  font-size: 24px;
  display: flex;
}
.category-chip__position-controls ion-icon {
  margin-left: 12px;
}
