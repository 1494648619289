.orders-planned-order-time-selector {
  width: 100%;
  margin: auto;
  max-width: 1440px;
  font-size: 1.125rem;
  font-weight: bold;
  color: var(--ion-color-secondary);
}

.orders-planned-order-time-selector-text {
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.react-datepicker__time-list-item--disabled {
  display:none;
}

.orders-planned-order-time-datepicker {
  margin: 0 auto;
  border-radius: 0.3rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orders-planned-order-time-datepicker-time {
  background-color: var(--ion-color-primary);
}

.orders-planned-order-time-datepicker-wrapper {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}



