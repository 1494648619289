.courier-item {
  height: 100px;
  width: 100%;
  background-color: var(--ion-background-color);
  box-shadow: var(--base-card-shadow);
  border-top-left-radius: 16px;
  border-bottom-right-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  color: var(--ion-color-primary);
}
.courier-item__avatar {
  height: 64px;
  width: 64px;
  min-height: 64px;
  min-width: 64px;
  background-color: var(--ion-color-light-tint);
  border-radius: 100%;
  border: 1px solid var(--ion-color-secondary);
  overflow: hidden;
}
.courier-item__infos {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  padding: 16px;
  color: var(--ion-color-secondary);
  font-size: 16px;
}
.courier-item__actions {
  height: 100%;
}
.courier-item__actions__action-buttons {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
