.products-options-edition-modal {
  padding: 16px;
  padding-bottom: 0;
  height: 100%;
}
.products-options-edition-modal__editor {
  width: 100%;
  min-height: 100%;
  height: 100%;
  padding-bottom: 16px;
  overflow-y: auto;
  /* display: flex;
  flex-direction: column; */
}
.products-options-edition-modal__editor__type-input {
  display: flex;
  /* width: 100%; */
  /* justify-content: space-between;
  padding: 16px 0; */
}
.products-options-edition-modal__editor__type-input__one {
  /* width: 100%;
  margin-right: 8px; */
  margin: 0 1rem;
}
.products-options-edition-modal__editor__type-input__two {
  /* width: 100%;
  margin-left: 8px; */
}
.products-options-edition-modal__editor__item-editor {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.products-options-edition-modal__editor__item-editor ion-button {
  margin: 0;
}
.products-options-edition-modal__editor__item-editor ion-icon {
  font-size: 2rem;
}
.products-options-edition-modal__editor__item-editor__name {
  width: 100%;
}
.products-options-edition-modal__editor__item-editor__price {
  width: 30%;
  margin-left: 8px;
}
.products-options-edition-modal__editor__options-items-frame {
  width: 100%;
  height: 35%;
  overflow-y: scroll;
  background-color: var(--ion-color-light-tint);
  padding: 8px;
  border-radius: 8px;
}
@media screen and (min-height: 600px) and (min-width: 768px) {
  .products-options-edition-modal__editor__options-items-frame {
    height: 110px;
  }
}
@media screen and (min-height: 768px) and (min-width: 768px) {
  .products-options-edition-modal__editor__options-items-frame {
    height: 200px;
  }
}
.products-options-edition-modal__editor__options-items-frame__item {
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 8px;
  font-weight: bold;
  color: var(--ion-color-secondary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  box-shadow: var(--base-card-shadow);
}
.products-options-edition-modal__editor__options-items-frame__item__price-action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.products-options-edition-modal__editor__options-items-frame__item__price-action__delete {
  font-size: 1.5rem;
  margin-left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.products-options-edition-modal__editor__options-items-frame__item--active {
  background-color: var(--ion-color-primary);
  color: white;
}
.products-options-edition-modal__editor__actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.products-options-edition-modal__editor__actions > ion-button {
  width: 100%;
}
.products-options-edition-modal__editor__name-input {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1rem;
}
.products-options-edition-modal__editor__name-input > .base-text-input {
  width: 100%;
}
.products-options-edition-modal__editor__type-input__one {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.products-options-edition-modal__editor__type-input__one__label {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 0.25rem;
}
.products-options-edition-modal__editor__type-input__two {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.products-options-edition-modal__editor__type-input__two__label {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 0.25rem;
}
.products-options-edition-modal__editor__type-input__color {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
}
.products-options-edition-modal__editor__type-input__color__label {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.products-options-edition-modal__editor__options-items-frame__item__available-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
}
