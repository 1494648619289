.orders-customer-item {
  min-height: 115px;
  background-color: var(--ion-color-secondary);
  color: white;
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  border: 0.2rem solid transparent;
  margin: 0.5rem 0;
  cursor: pointer;
}
.orders-customer-item--selected {
  background-color: var(--ion-color-primary);
}
.orders-customer-item--selected {
  border: 0.2rem solid var(--ion-color-primary);
}
.orders-customer-item__name-header {
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.orders-customer-item__address-container {
  /* padding-top: 1rem; */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.orders-customer-item__address-container__infos span {
  display: block;
  font-size: 0.75rem;
}
.orders-customer-item__address-container__distance {
  display: flex;
  font-size: 0.75rem;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}
.orders-customer-item__address-container__distance span {
  margin: 0 0.25rem;
}
