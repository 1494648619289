.restaurant-order-history {
    display: grid;
    gap: 1rem;
    grid-template-areas:
      "date-input"  
      "order-on-place"
      "order-delivery"

  }
  .restaurant-order-history__earning {
    font-weight: bold;
    color: var(--ion-color-primary);
    font-size: 2rem;
  }
  @media screen and (min-width: 900px) {
    .restaurant-order-history {
      display: grid;
      gap: 1rem;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "date-input date-input"
        "order-on-place order-delivery"
    }
  }
  