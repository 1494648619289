.order-detail-modal-container {
  --height: 100%;
  --width: 100%;
  --min-height: 100%;
  --min-width: 100%;
  height: 100%;
  width: 100%;
  background-color: red;
}



.order-detail-modal {
  padding: 1rem;
  height: 100%;
}

.order-detail-modal__cart {
  height: calc(100% - 80px);
}

.order-detail-modal__cart--as-restaurant {
  /* minus height of 80px to implement more details */
  /* height: calc(100% - 132px); */
  height: calc(100% - 53px);
}