.orders-mini-cart {
  height: 112px;
  width: calc(100% - 32px);
  position: fixed;
  cursor: pointer;
  
}
.orders-mini-cart__cart {
  height: 100%;
  width: 100%;
  background-color: var(--ion-color-light-tint);
  margin: auto;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: var(--base-card-shadow);
}
.orders-mini-cart__total {
  height: 32px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  font-weight: bold;
  color: white;
  background-color: var(--ion-color-secondary);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.orders-mini-cart__products {
  height: 100%;
  display: flex;
  overflow-x: scroll;
  padding: 8px 4px;
  overflow-y: hidden;
}
.orders-mini-cart__products__product-item {
  height: 64px;
  width: 64px;
  min-width: 64px;
  margin: 0 4px;
  border: 2px solid var(--ion-color-primary);
  border-top-left-radius: 24px;
  border-bottom-right-radius: 24px;
  overflow: hidden;
  position: relative;
}
.orders-mini-cart__products__product-item ion-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.orders-mini-cart__products__product-item__amount {
  position: absolute;
  bottom: 3%;
  right: 10%;
}
