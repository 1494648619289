.products-category-selector {
  width: 100%;
  margin: auto;
  max-width: 1440px;
  padding: 0 1rem;
  font-size: 1.125rem;
  font-weight: bold;
  color: var(--ion-color-secondary);
}
.products-category-selector--order-mode {
  padding: 0;
}
.products-category-selector__category-list {
  display: flex;
  padding: 1rem 0;
  overflow-x: auto;
}
.products-category-selector__category-list div {
  margin-right: 0.5rem;
}
