.delivery-setup-editor {
  display: grid;
  gap: 1rem;
  grid-template-areas:
    "delivery-delay"
    "click-and-collect-delay"
    "delivery-cities"
    "delivery-open"
    "delivery-fees"
    "delivery-website"
    "door-payment"
    "discount"
    "production-mode";
}

.delivery-setup-editor__slug-setup {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delivery-setup-editor__slug-setup__availability-indicator {
  padding: 1rem;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0.6rem;
}

.delivery-setup-editor__opener {
  display: flex;
  align-items: center;
  font-weight: 1.125rem;
  font-weight: bold;
  justify-content: space-between;
  margin: 1rem 0;
}

.delivery-setup-editor__delivery-cities__list {
  height: 300px;
  background-color: rgb(238, 238, 238);
  padding: 1rem;
  border-radius: 0.5rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.delivery-setup-editor__delivery-cities__list__item {
  padding: 1rem;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delivery-setup-editor__delivery-cities__list__item__name {
  font-weight: bold;
}

.delivery-setup-editor__delivery-cities__list__item__delete-btn {
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.delivery-setup-editor--delivery-delay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 900px) {
  .delivery-setup-editor {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "delivery-delay click-and-collect-delay"
      "delivery-cities delivery-open"
      "delivery-fees production-mode"
      "delivery-fees discount"
      "delivery-fees door-payment"
      "delivery-website delivery-website";
  }
  .delivery-setup-editor--delivery-delay {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
