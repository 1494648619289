.restaurant-q-r-code-modal {
  padding: 16px;
  height: 100%;
  width: 100%;
}
.restaurant-q-r-code-modal__content {
  height: calc(100% - 50px);
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.restaurant-q-r-code-modal__content__text {
  text-align: center;
  font-weight: bold;
  padding: 32px;
  color: var(--ion-color-secondary);
  margin-bottom: 32px;
}
/* .restaurant-q-r-code-modal__content__qr {
    text-align: center;
    font-weight: bold;
    padding: 32px;
    color: var(--ion-color-secondary);
    margin-bottom: 32px;
  } */
