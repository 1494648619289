.order-summary {
  height: 100%;
  width: 100%;
}

.order-summary__order-infos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  margin-bottom: 16px;
}

.order-summary__order-infos__navigation {
  color: var(--ion-color-secondary);
  cursor: pointer;
}

.order-summary__order-infos__infos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.order-summary__order-infos span {
  font-weight: bold;
  color: var(--ion-color-secondary);

}

.order-summary__order-infos-text-small span {
  font-size: 14px;
}

.order-summary__order-infos-text-large span {
  font-size: 18px;
}

.order-summary__order-infos-text-small span:first-of-type {
  font-size: 16px;
}

.order-summary__order-infos-text-large span:first-of-type {
  font-size: 19px;
}

.order-summary__cart {
  /* height: calc(100% - 112px); */
  height: calc(100% - 188px);
  width: 100%;
  background-color: var(--ion-color-light-tint);
  margin: auto;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.order-summary__cart--as-delivery {
  height: calc(100% - 16px);
}

.order-summary__products {
  padding: 8px;
  overflow-y: scroll;
  height: 100%;
}

.order-summary__total {
  height: 32px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  font-weight: bold;
  color: white;
  background-color: var(--ion-color-secondary);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.order-summary__products__spacer {
  width: 100%;
  height: 1px;
}

.order-summary__mini-infos {
  min-height: 45px;
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-summary__mini-infos__total-amount,
.order-summary__mini-infos__distance,
.order-summary__mini-infos__hour {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ion-color-primary);
  height: calc(100% - 0.75rem);
}

.order-summary__mini-infos__hour__planned-time {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ion-color-secondary);
  height: calc(100% - 0.75rem);
}

.order-summary__mini-infos__total-amount>span,
.order-summary__mini-infos__distance>span,
.order-summary__mini-infos__hour>span,
.order-summary__mini-infos__hour__planned-time>span {
  color: var(--ion-color-secondary);
  margin-left: 0.5rem;

  font-weight: bold;
}

.order-summary__mini-infos__text-small>span {
  font-size: 0.75rem;
}

.order-summary__mini-infos__text-large>span {
  font-size: 1rem;
}


.order-summary__mini-infos__distance {
  border-right: 1px solid var(--ion-color-light-shade);
}