.products-options-editor {
  height: 100%;
  width: 100%;
}
.products-options-editor__title {
  font-size: 1.125rem;
  font-weight: bold;
  color: var(--ion-color-secondary);
  margin-bottom: 1rem;
  display: block;
}
.products-options-editor__products-options {
  height: calc(90% - 5rem);
  width: 100%;
  background-color: var(--ion-color-light-tint);
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  overflow-y: scroll;
  padding: 0.5rem;
}
.products-options-editor__products-options__no-state {
  width: 100%;
  text-align: center;
  padding: 1rem;
  color: var(--ion-color-secondary);
  font-weight: bold;
}
