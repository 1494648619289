.base-image-input {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 16px; */
  position: relative;
  width: 100%;
}
.base-image-input__hidden-input {
  position: absolute;
  height: 0;
  width: 0;
}
.base-image-input__visualizer {
  height: 200px;
  margin-bottom: 16px;
  width: 100%;
  background-color: #f8f8f8;
  border: 1px solid grey;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-size: calc(100% - 8px);
  background-position: 50%;
}
.base-image-input__input-container {
  height: 100px;
  width: 100%;
  /* background-color: white; */
  border: 1px dotted grey;
  border-radius: 8px;
  color: var(--primary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 32px;
}
.base-image-input__input-container--drag-over {
  background-color: rgba(100, 148, 237, 0.15);
}

.base-image-input__input-container__text {
  text-align: center;
}
.base-image-input__input-container__text__action {
  color: var(--primary-color);
  font-weight: bold;
}
.base-image-input__input-container__text__action:hover {
  cursor: pointer;
  text-decoration: underline;
}
/* @media screen and (min-width: 500px) {
  .base-image-input {
    flex-direction: row;
  }
  .base-image-input__visualizer {
    margin-bottom: 0;
    min-width: 200px;
    width: 200px;
  }
  .base-image-input__input-container {
    height: 200px;
    width: calc(100% - 216px);
  }
} */
