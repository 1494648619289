.products-infos-editor {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}
.products-infos-editor__preview-and-picture {
  width: 136px;
  margin: 0 auto 0 auto;
}
.products-infos-editor__preview-and-picture__hidden-input {
  height: 0;
  width: 0;
}
.products-infos-editor__infos-editor {
  width: 100%;
}
.products-infos-editor__infos-editor__has-options-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0;
  color: var(--ion-color-secondary);
}
.products-infos-editor__infos-editor__has-options-container span {
  margin-left: 8px;
}

@media screen and (max-width: 500px) {
  .products-infos-editor__infos-editor__has-options-container {
    margin: 0.45rem 0;
  }
}
