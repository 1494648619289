.products-grid {
  width: 100%;
  margin: auto;
  max-width: 1440px;
  padding: 0 1rem;
  font-size: 1.125rem;
  font-weight: bold;
  color: var(--ion-color-secondary);
}
.products-grid--order-mode {
  padding: 0;
}
.products-grid__grid {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, calc((100% - 1rem) / 2));
  grid-gap: 1rem;
  padding: 1rem 0;
  justify-items: center;
  align-items: center;
  /* background-color: blue; */
}
/* .products-grid__grid--order-mode {
  overflow-y: scroll;
} */
@media (min-width: 560px) {
  .products-grid__grid {
    grid-template-columns: repeat(3, calc((100% - 2rem) / 3));
  }
}
@media (min-width: 730px) {
  .products-grid__grid {
    grid-template-columns: repeat(4, calc((100% - 3rem) / 4));
  }
}

@media (min-width: 875px) {
  .products-grid__grid {
    grid-template-columns: repeat(5, calc((100% - 4rem) / 5));
  }
  .products-grid__grid--order-mode {
    grid-template-columns: repeat(4, calc((100% - 3rem) / 4));
  }
}

@media (min-width: 1024px) {
  .products-grid__grid {
    grid-template-columns: repeat(6, calc((100% - 5rem) / 6));
  }
  .products-grid__grid--order-mode {
    grid-template-columns: repeat(4, calc((100% - 3rem) / 4));
  }
}

@media (min-width: 1200px) {
  .products-grid__grid--order-mode {
    grid-template-columns: repeat(5, calc((100% - 4rem) / 5));
  }
}

@media (min-width: 1450px) {
  .products-grid__grid {
    grid-template-columns: repeat(8, calc((100% - 6rem) / 8));
  }
  .products-grid__grid--order-mode {
    grid-template-columns: repeat(6, calc((100% - 5rem) / 6));
  }
}
