.couriers-grid {
  width: 100%;
  margin: auto;
  max-width: 1440px;
  padding: 0 1rem;
  font-size: 1.125rem;
  font-weight: bold;
  color: var(--ion-color-secondary);
}
.couriers-grid__grid {
  height: 100%;
  width: 100%;
  display: grid;
  grid-gap: 1rem;
  padding: 1rem 0;
}

@media (min-width: 624px) {
  .couriers-grid__grid {
    grid-template-columns: repeat(2, calc((100% - 1rem) / 2));
  }
}
