.courier-no-state-item {
  height: 100px;
  width: 100%;
  background-color: var(--ion-background-color);
  box-shadow: var(--base-card-shadow);
  border-top-left-radius: 16px;
  border-bottom-right-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  color: var(--ion-color-primary);
  text-align: center;
}
