.setting-card {
  border-radius: 0.5rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.setting-card__title {
  font-size: 1.25rem;
  color: rgb(54, 64, 81);
  font-weight: bold;
  margin-bottom: 1rem;
}

.setting-card__title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.setting-card__subtitle {
  font-size: 1.25rem;
  color: #000;
  font-weight: bold;
  margin-bottom: 1rem;

}
.setting-card__control {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 660px) {
  .setting-card__title-container {

    flex-direction: column;

  }
}