.orders-tabs-layout {
  min-height: 100%;
  width: 100%;
  display: block;
  padding: 1rem;
}
.orders-tabs-layout__no-state-container {
  height: calc(100vh - 250px);
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 1280px) {
  .orders-tabs-layout {
    display: none;
  }
}
