.courier-account-editor {
  display: grid;
  gap: 1rem;
  grid-template-areas:
    "password-update"
    "profile-picture"
    "courier-base-infos"
    "user-email"
    "logout-btn";
}
.courier-account-editor__logo-input {
  display: flex;
  justify-content: space-between;
}
.courier-account-editor__logo-input__logo-display {
  height: 130px;
  min-width: 130px;
  margin-right: 1rem;
  background-color: rgb(238, 238, 238);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}
@media screen and (min-width: 900px) {
  .courier-account-editor {
    display: grid;
    gap: 1rem;
    grid-template-areas:
      "password-update courier-base-infos"
      "profile-picture user-email"
      "profile-picture logout-btn";
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
}
