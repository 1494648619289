.home-layout-header__toolbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  max-width: 1440px;
  margin: auto;
}
.home-layout-header__toolbar-title {
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
}

.home-layout-header__action-button--disabled {
  height: 45px;
  width: 45px;
  color: white;
  background: gray;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.75rem;
  border-radius: 4px 16px;
}
.home-layout-header__action-button {
  cursor: pointer;
  height: 45px;
  width: 45px;
  color: white;
  background: linear-gradient(
    0deg,
    var(--ion-color-primary) 0%,
    var(--ion-color-primary-top-gradient) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.75rem;
  border-radius: 4px 16px;
}
.home-layout-header__action-button--empty {
  height: 45px;
  width: 45px;
}
