.orders-customer-research {
  height: 100%;
  width: 100%;
  min-height: 350px;
}
.orders-customer-research__title {
  font-size: 1.125rem;
  font-weight: bold;
  color: var(--ion-color-secondary);
}
.orders-customer-research__search-results-frame {
  height: calc(90% - 8rem);
  width: 100%;
  background-color: var(--ion-color-light-tint);
  margin: 1rem 0;
  border-radius: 0.5rem;
  overflow-y: scroll;
  padding: 0.5rem;
}
.orders-customer-research__search-results-frame__ask-for-input {
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.125rem;
  color: var(--ion-color-secondary);
  text-align: center;
}
.orders-customer-research__search-results-frame__loading-container {
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.125rem;
  color: var(--ion-color-secondary);
  text-align: center;
}
.orders-customer-research__search-results-frame__no-results-container {
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.125rem;
  color: var(--ion-color-secondary);
  text-align: center;
}
