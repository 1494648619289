.setting-door {
  cursor: pointer;
  width: 100%;
  height: 8rem;
  border-radius: 0.5rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: transform 100ms ease-in-out, box-shadow 100ms ease-in-out;
}

.setting-door:hover {
  transform: translateY(-0.25rem);
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.setting-door__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 2rem;
}
.setting-door__content__name {
  font-size: 1.5rem;
  font-weight: bold;
  max-width: 256px;
  color: rgb(54, 64, 81);
}
.setting-door__content__icon {
  font-size: 5rem;
  font-weight: bold;
  max-width: 256px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.2);
}
