.no-restaurant-prompt {
  width: 100%;
  padding: 16px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1440px;
  margin: auto;
}
.no-restaurant-prompt__text {
  display: block;
  font-weight: bold;
  color: var(--ion-color-secondary);
  margin: 2rem 0;
}
.no-restaurant-prompt__qr-icon {
  margin-right: 8px;
}
.no-restaurant-prompt__scanner {
  width: 100%;
  max-width: 350px;
}
