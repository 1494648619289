.base-button {
  margin: 0.5rem 0;
  position: relative;
}
.base-button--big {
  font-size: 1.25rem;
}
.base-button--tight {
  font-size: 1rem;
  margin: 0;
}
.base-button__text--invisible {
  opacity: 0;
}
.base-button__loading-spinner {
  position: absolute;
  left: calc(50% - (28px / 2));
  top: calc(50% - (28px / 2));
}
