.setting-label-and-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 1rem 0;
}
.setting-label-and-info span:first-of-type {
  color: #364051;
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
}
.setting-label-and-info span:last-of-type {
  color: #364051;
  font-size: 1rem;
}
