.order-item {
  height: 170px;
  width: 100%;
  background-color: var(--ion-background-color);
  border-radius: 4px;
  border-top-left-radius: 32px;
  border-bottom-right-radius: 32px;
  box-shadow: var(--base-card-shadow);
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  cursor: pointer;
}

.order-item-main-container {
  position: relative;
}

.order-item--canceled-container {
  position:absolute;
  left: 45%;
  top: 45%;
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
}
.order-item--delivery-mode {
  border-bottom-right-radius: 4px;
  height: 218px;
}
.order-item__header {
  height: 80px;
  width: 100%;
  background-color: var(--ion-color-secondary);
  border-top-left-radius: 32px;
  border-top-right-radius: 4px;
  display: flex;
}
.order-item--local-unpaid .order-item__header {
  background-color: #f54938;
}
.order-item--web-paid .order-item__header {
  background-color: #6358f7;
}

.order-item--selected {
  border-style: solid;
  border-width: 3px;
  border-color: var(--ion-color-primary);
}
/* .order-item__header--selected {
  background-color: var(--ion-color-primary);
} */
.order-item__header__position-indicator {
  position: absolute;
  background-color: var(--ion-color-primary);
  color: white;
  font-weight: bold;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.order-item__header__products {
  min-width: 124px;
  height: 100%;
  /* background-color: blue; */
  border-top-left-radius: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem 0 0.25rem;
}

.order-item__header__products__main {
  height: 72px;
  width: 72px;
  border: 2px solid white;
  border-top-left-radius: 28px;
  border-bottom-right-radius: 28px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: var(--ion-color-secondary-tint);
  overflow: hidden;
}
.order-item__header__products ion-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.order-item__header__products__aside {
  width: 32px;
  height: 72px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.order-item__header__products__aside__first {
  height: 32px;
  width: 32px;
  border: 1px solid white;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 2px;
  border-bottom-left-radius: 2px;
  background-color: var(--ion-color-secondary-tint);
  overflow: hidden;
}

.order-item__header__products__aside__second {
  height: 32px;
  width: 32px;
  border: 1px solid white;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 2px;
  border-bottom-left-radius: 2px;
  background-color: var(--ion-color-secondary-tint);
  overflow: hidden;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-item__header__customer-infos {
  border-left: 1px solid var(--ion-color-light-tint);
  height: 100%;
  width: calc(100% - 124px);
  padding: 0.25rem 0.5rem;
}

.order-item__header__planning-type__planned__type--container {
  display: flex;
  flex-direction: column;
}

.order-item__header__planning-type__planned__type--phone {
  font-size: 0.85rem;
}
.order-item__header__customer-infos__infos__container> span {
  color: white;
  font-weight: bold;
  font-size: 0.9rem;
  height: 100%;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-item__header__customer-infos__no-delivery {
  color: white;
  font-weight: bold;
  font-size: 1rem;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-item__header__customer-infos__infos {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 70%;
  justify-content: center;
}

.order-item__header__customer-infos__infos__container {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
}

.order-item__header__customer-infos__infos > span {
  color: white;
  opacity: 0.7;
  font-size: 0.75rem;
  /* line-height: 0.875rem; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 0.5rem);
}
/* .order-item__header__customer-infos__infos > span:first-of-type {
  color: white;
  font-weight: bold;
  font-size: 0.875rem;
  opacity: 1;
} */

.order-item__header__customer-infos__infos__name__planning-type {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.order-item__header__customer-infos__infos__name__planning-type > span:first-of-type {
  color: white;
  font-weight: bold;
  font-size: 0.875rem;
  opacity: 1;
}

.order-item__header__customer-infos__infos__planned {
  background-color: orange;
  border-radius: 0.3rem;
  width: auto;
  padding: 0.2rem;
  margin-left: 0.6rem;
  font-weight: 300;
  font-size: 0.65rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 0.5rem);
  color: #fff;
}

.order-item__header__customer-infos__infos__now {
  background-color: green;
  border-radius: 0.3rem;
  width: auto;
  padding: 0.2rem;
  margin-left: 0.6rem;
  font-weight: 300;
  font-size: 0.65rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 0.5rem);
  color: #fff;
}


.order-item__infos-and-actions {
  color: var(--ion-color-secondary);
  height: calc(100% - 80px);
  border-bottom-right-radius: 32px;
  border-bottom-left-radius: 4px;
  padding: 0 0.5rem;
  /* background-color: green; */
  display: flex;
  flex-direction: column;
}
.order-item__infos-and-actions__top {
  flex: 1;
  width: 100%;
  min-height: 45px;
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--ion-color-light-shade);
}
.order-item__infos-and-actions__top__order-number {
  font-weight: bold;
  flex: 1;
  text-align: center;

  font-size: 1rem;
}
.order-item__infos-and-actions__top__action {
  flex: 1;
  text-align: center;
  border-left: 1px solid var(--ion-color-light-shade);
  height: calc(100% - 0.75rem);
  /* padding: 0.5rem; */
}
.order-item__infos-and-actions__top__action__button {
  padding-left: 0.5rem;
}

.order-item__infos-and-actions__top__action__status {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ion-color-secondary);
  font-size: 0.75rem;
  font-weight: bold;
  height: 100%;
}
.order-item__infos-and-actions__top__action__status .status-dot {
  height: 0.825rem;
  width: 0.825rem;
  background-color: var(--ion-color-infos);
  border-radius: 100%;
  margin-right: 0.5rem;
}

.order-item__infos-and-actions__top__action__status .success-dot {
  background-color: var(--ion-color-success);
}
.order-item__infos-and-actions__top__action__status .warning-dot {
  background-color: var(--ion-color-warning);
}

.order-item__infos-and-actions__top__action__status .courier-profile-picture {
  height: 32px;
  width: 32px;
  border-radius: 100%;
  background-color: var(--ion-color-medium-shade);
  overflow: hidden;
  margin-right: 1rem;
  border: 1px solid var(--ion-color-secondary);
}
.order-item__infos-and-actions__top__action__status
  .courier-profile-picture
  ion-img {
  object-fit: cover;
}

.order-item__infos-and-actions__bottom {
  min-height: 45px;
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.order-item__infos-and-actions__bottom--delivery-mode {
  min-height: 90px;
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
}
.order-item__infos-and-actions__bottom__total-amount,
.order-item__infos-and-actions__bottom__distance,
.order-item__infos-and-actions__bottom__hour {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ion-color-primary);
  height: calc(100% - 0.75rem);
}
.order-item__infos-and-actions__bottom__hour__planned-time {

  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ion-color-secondary);
  height: calc(100% - 0.75rem);

}

.order-item__infos-and-actions__bottom__total-amount > span,
.order-item__infos-and-actions__bottom__distance > span,
.order-item__infos-and-actions__bottom__hour > span,
.order-item__infos-and-actions__bottom__hour__planned-time > span {
  color: var(--ion-color-secondary);
  margin-left: 0.5rem;
  font-size: 0.75rem;
  font-weight: bold;
}
.order-item__infos-and-actions__bottom__distance {
  border-left: 1px solid var(--ion-color-light-shade);
  border-right: 1px solid var(--ion-color-light-shade);
}


.order-item__header__planning-type__now__type {
  background-color: green;
  border-radius: 0.3rem;
  padding: 0.3rem;
  margin: 0.6rem;
  width: auto;
  font-weight: bold;
  font-size: 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 0.5rem);

}

.order-item__header__planning-type__planned__type {
  background-color: orange;
  border-radius: 0.3rem;
  width: auto;
  padding: 0.3rem;
  margin: 0.6rem;
  font-weight: bold;
  font-size: 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 0.5rem);

}

