.restaurant-account-editor {
  display: grid;
  gap: 1rem;
  grid-template-areas:
    "password-update"
    "my-infos"
    "logout-btn";
}
@media screen and (min-width: 900px) {
  .restaurant-account-editor {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "password-update my-infos"
      "password-update logout-btn";
  }
}
