.contextual-layout__main {
  width: 100%;
  height: 100%;
  padding: 1rem;
  padding-bottom: 2rem;
}

.contextual-layout__main__content {
  margin: auto;
  height: 100%;
  /* max-width: 24rem; */
  max-width: 384px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.contextual-layout__main__content--wide-enabled {
  max-width: 1440px;
}
@media screen and (min-width: 1024px) {
  .contextual-layout__main__content--wide-enabled {
    max-width: 1440px;
  }
}

@media screen and (max-width: 400px) {
  .contextual-layout__main {
    padding-bottom: 1rem;
  }
}
