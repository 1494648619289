.orders-no-states {
  /* background-color: blue; */
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
}
.orders-no-states--segment-contraints {
  height: calc(100% - 35px);
}

.orders-no-states__icon-container__icon {
  color: var(--ion-color-primary);
  font-size: 8rem;
  opacity: 0.3;
  margin-bottom: 2rem;
}
.orders-no-states__text {
  color: var(--ion-color-secondary);
  font-size: 1.25rem;
  text-align: center;
}
