.products-grid-item {
  height: 160px;
  width: 160px;
  background-color: var(--ion-background-color);
  box-shadow: var(--base-card-shadow);
  border-radius: 0.5rem;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}
.products-grid-item__picture-and-infos {
  height: 50%;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.products-grid-item--unavailable {
  opacity: 0.5;
  filter: grayscale(100%);
}

.products-grid-item__product-unavailable {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  font-weight: bold;
  font-size: 1.25rem;
}

.products-grid-item__product-unavailable--edit {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 2px;
  right: 2px;
  opacity: 1;
  font-weight: normal;
  font-size: 0.75rem;
  background-color: red;
  border-radius: 50%;
  color: #fff;
  padding: 0.1rem;
  height: 18px;
  width: 18px;
}

.products-grid-item__product-long-desc {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  font-weight: bold;
  font-size: 1.25rem;
}

.products-grid-item__picture-and-infos ion-img {
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
}
.products-grid-item__picture-and-infos__picture {
  position: relative;
}
.products-grid-item__picture-and-infos__placeholder {
  width: 100%;
  height: 100%;
  background-color: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: var(--ion-color-primary);
  opacity: 0.8;
  background-color: var(--ion-color-light-tint);
}
.products-grid-item__picture-and-infos__composition-indicator {
  position: absolute;
  height: 18px;
  width: 18px;
  background-color: var(--ion-color-primary);
  bottom: 0;
  left: 0;
  border-top-right-radius: 100%;
}
.products-grid-item__picture-and-infos__product-price {
  position: absolute;
  background-color: var(--ion-color-secondary);
  bottom: 0;
  right: 0;
  border-top-left-radius: 2rem;
  color: white;
  font-size: 0.825rem;
  padding: 1px 0.25rem 1px 1rem;
}
.products-grid-item__product-name {
  height: 20%;
  width: 100%;
  font-size: 0.825rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-weight: bold;
  color: var(--ion-color-secondary);
}

.products-grid-item__product-description {
  height: 22%;
  width: 100%;
  font-size: 0.7rem;
  text-align: center;
  padding: 0 0.3rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  font-weight: 400;
  opacity: 0.7;
  color: var(--ion-color-secondary);
  flex-wrap: wrap;
  text-overflow: ellipsis;
  white-space: normal;
}

.products-grid-item__product-show-long-desc {
  height: 13%;
  width: 100%;
  font-size: 0.65rem;
  padding: 0 0.25rem;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  font-weight: 400;
  opacity: 0.7;
  color: var(--ion-color-secondary);
  flex-wrap: wrap;
  text-overflow: ellipsis;
  white-space: normal;
}

@media (max-width: 360px) {
  .products-grid-item {
    width: 100%;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

/* Animation pour la disparition */
@keyframes slideDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

/* Classe pour l'apparition */
.slide-up {
  animation: slideUp 0.3s ease-out forwards;
}

/* Classe pour la disparition */
.slide-down {
  animation: slideDown 0.3s ease-out forwards;
}
