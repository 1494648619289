.logo,
.logo--base {
  width: 8rem;
}
.logo--xxs {
  width: 2rem;
}
.logo--xs {
  width: 4rem;
}
.logo--sm {
  width: 6rem;
}
.logo--md {
  width: 10rem;
}
.logo--lg {
  width: 12rem;
}
.logo--xl {
  width: 14rem;
}
