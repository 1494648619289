.reset-password-logo {
  width: 100%;
  max-width: 24rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 15% 0; */
  padding: 1rem 0 4rem 0;
}

.reset-password-form {
  width: 100%;
  /* height: 100%; */
  max-width: 24rem;
}

/* @media screen and (min-height: 600px) {
  .reset-password-logo {
    padding-top: 25%;
  }
}
@media screen and (min-height: 720px) {
  .reset-password-logo {
    padding-top: 50%;
  }
}
@media screen and (min-width: 640px) {
  .reset-password-logo {
    padding: 3rem 0;
  }
} */
