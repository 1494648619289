.add-product-page__controls {
  width: 100%;
  display: flex;
}
.add-product-page__controls__button-container {
  width: 50%;
}
.add-product-page__controls__button-container:first-of-type {
  padding-right: 0.5rem;
}
.add-product-page__controls__button-container:last-of-type {
  padding-left: 0.5rem;
}
