.order-product-options-modal {
  padding: 16px;
  height: 100%;
}
.order-product-options-modal__options-frame {
  width: 100%;
  height: calc(100% - 10rem);
  background-color: var(--ion-color-light-tint);
  border-radius: 8px;
  padding: 16px;
  overflow-y: auto;
}
.order-product-options-modal__confirm-button {
  margin-top: 16px;
}

.order-product-options-modal__quantity-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0 0.25rem;

}

.order-product-options-modal__quantity-setter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.order-product-options-modal__quantity-plus-minus {
  padding : 0.5rem 0.9rem;
  font-size: 1.25rem;
  cursor: pointer;
  color:#fff;
  border-radius: 0.4rem;
  width: 100%;
  background-color: var(--ion-color-primary);
}

.order-product-options-modal__quantity-plus-minus:hover {
  opacity: 0.7;
}

.order-product-options-modal__quantity-amount {
  font-size: 1.25rem;
  padding : 0.5rem 0.9rem;
}
@media screen and (min-height: 600px) and (min-width: 768px) {
  .order-product-options-modal__options-frame {
    height: 320px;
  }
}
@media screen and (min-height: 768px) and (min-width: 768px) {
  .order-product-options-modal__options-frame {
    height: 410px;
  }
}
.order-product-options-modal__options-frame__options > span {
  color: var(--ion-color-secondary);
  margin-bottom: 16px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}
.order-product-options-modal__options-frame__options > span > span:last-child {
  font-weight: normal;
  font-size: 16px;
  font-style: italic;
}
.order-product-options-modal__options-frame__options__item--unavailable {
  opacity: 0.5;
  filter: grayscale(100%);
  
}
.order-product-options-modal__options-frame__options__item {
  cursor: pointer;
  color: var(--ion-color-secondary);
  background-color: white;
  margin-bottom: 8px;
  padding: 16px;
  box-shadow: var(--base-card-shadow);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
}
.order-product-options-modal__options-frame__options__item:last-child {
  margin-bottom: 16px;
}
.order-product-options-modal__options-frame__options__item span {
  font-weight: bold;
}
.order-product-options-modal__options-frame__options__item--active {
  background-color: var(--ion-color-primary);
  color: white;
}


