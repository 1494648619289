.products-grid-no-state-item {
  height: 160px;
  width: 100%;
  background-color: var(--ion-background-color);
  box-shadow: var(--base-card-shadow);
  border-radius: 0.5rem;
  padding-top: 1rem;
}
.products-grid-no-state-item__picture-and-infos {
  height: 50%;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.products-grid-no-state-item__picture-and-infos ion-icon {
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
  color: var(--ion-color-primary);
  opacity: 0.5;
}

.products-grid-no-state-item__product-name {
  height: 50%;
  width: 100%;
  font-size: 0.825rem;
  text-align: center;
  padding: 1rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
