.orders-full-layout {
  display: none;
}
.orders-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0.5rem;
  height: 100%;
  background-color: var(--ion-background-color);
  border-radius: 1rem;
  box-shadow: var(--base-card-shadow);

  /* padding: 0.5rem; */
}
.orders-column__header {
  font-weight: bold;
  color: var(--ion-color-secondary);
  width: 100%;
  text-align: center;
  padding: 0.5rem;
  /* padding-top: 0; */
  border-bottom: 1px solid var(--ion-color-light-shade);
}
.orders-column__content {
  height: 100%;
  width: 100%;
  /* background-color: blue; */
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 0 1rem;
  padding-top: 1rem;
  overflow-y: scroll;
  scrollbar-width: thin;
}
.orders-column__content__spacer {
  width: 100%;
  height: 1px;
}
@media screen and (min-width: 1280px) {
  .orders-full-layout {
    display: flex;
    justify-content: space-between;
    /* background-color: blue; */
    height: 100%;
    width: 100%;
    max-width: 1440px;
    padding: 1rem 1rem 1rem 1rem;
    margin: auto;
  }
}
