.base-modal-header {
  margin-bottom: 1rem;
  color: var(--ion-color-secondary);
  font-weight: bold;
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 1.25rem;
}

.base-modal-header__action-container {
  display: flex;
}

.base-modal-header__close-icon {
  cursor: pointer;
  font-size: 1.75rem;
  padding: 0 0.5rem;
}