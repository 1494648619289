.products-category-color-selector {
  /* height: 216px; */
  /* height: 100%; */
  /* max-height: 300px; */
  height: 400px;
  width: 100%;
  background-color: var(--ion-color-light-tint);
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  overflow-y: scroll;
  padding: 0.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(72px, 1fr));
  grid-gap: 0.5rem;
}

@media screen and (min-width: 767px) {
  .products-category-color-selector {
    height: 180px;
    /* max-height: 30vh; */
  }
}
@media screen and (min-height: 768px) {
  .products-category-color-selector {
    height: 280px;
    /* max-height: 30vh; */
  }
}
