.base-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.base-form__fields {
  width: 100%;
}

.base-form__submit-button {
  width: 100%;
  margin-top: 2.5rem;
}
