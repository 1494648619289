.settings-page__settings-grid {
  display: grid;
  gap: 1rem;
  padding: 1rem;
  grid-template-columns: repeat(1, 1);
  max-width: 1440px;
  margin: auto;
}

.settings-page__header {
  width: 100%;
  max-width: 1440px;
  margin: auto;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.settings-page__header__title {
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(54, 64, 81);
}
.settings-page__header__close-btn {
  cursor: pointer;
  font-size: 2rem;
  color: rgb(54, 64, 81);
  display: flex;
  justify-content: center;
  align-items: center;
}
.settings-page__content {
  max-width: 1440px;
  margin: auto;
  padding: 1rem;
}

@media screen and (min-width: 900px) {
  .settings-page__settings-grid {
    grid-template-columns: repeat(2, calc((100% - 1rem) / 2));
  }
}
