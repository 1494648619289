.restaurant-earnings {
  display: grid;
  gap: 1rem;
  grid-template-areas:
    "date-input"
    "cash-earning"
    "cb-earning"
    "stripe-earning"
    "total-earning";
}
.restaurant-earnings__earning {
  font-weight: bold;
  color: var(--ion-color-primary);
  font-size: 2rem;
}
.react-datepicker__input-container {
  border-width: 1px;
  border-style: solid;
  max-width: 350px;
  /* border-color: red; */
  border-radius: 6px;
  padding: 0.5rem 0.5rem;
}
.react-datepicker__input-container input {
  border-style: none;
  width: 100%;
}

.react-datepicker__input-container input:focus {
  outline: none;
}
@media screen and (min-width: 900px) {
  .restaurant-earnings {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "date-input date-input date-input date-input"
      "total-earning total-earning total-earning total-earning";
  }
}
