.role-button__main-container {
  position: relative;

}

.role-button {
  min-height: 117px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 250ms ease;
  width: calc(50% - 0.5rem);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  padding: 1rem;
  border-radius: 2rem 4px 2rem 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  
}


.role-button__ion-img {
  min-height: 56px;
  width: 96px;
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
}


.role-button__disabled {
  position: absolute;
  text-align: center;
  opacity: 1;
  color: var(--ion-color-primary);
  left: 30%;
  top: 30%;
}
.role-button--not-selected {
  background:rgba(255,255,255,0.3);
  opacity: 0.5;
  filter: grayscale(100%);
}
.role-button__label {
  color: var(--ion-color-secondary);
  font-weight: 700;
  font-size: 1.125rem;
}
.role-button--as-new-order-choice {
  width: 100%;
  min-height: 180px;
  justify-content: center;
  text-align: center;
  margin: 1rem 0;
}
