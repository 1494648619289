.deliveries-list {
  padding: 0 16px;
  max-width: 1440px;
  margin: auto;
}
.deliveries-list--wno-state {
  height: 100%;
  width: 100%;
}
.deliveries-list__no-state {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
}
.deliveries-list__no-state__icon {
  color: var(--ion-color-primary);
  font-size: 96px;
  opacity: 0.33;
}
.deliveries-list__no-state__text {
  color: var(--ion-color-secondary);
  text-align: center;
  font-size: 16px;
}
.deliveries-list__optimize-button {
  margin: 1rem 0 1.5rem 0;
}
