/* Remove the firebase emulator banner */
.firebase-emulator-warning {
  display: none;
}
:root {
  --ion-background-color: #ffffff;

  --ion-color-primary: #fe8f2e;
  --ion-color-primary-top-gradient: #ffd8b6;
  --ion-color-primary-rgb: 254, 143, 46;
  --ion-color-primary-contrast: #fe8f2e;
  --ion-color-primary-contrast-rgb: 254, 143, 46;
  --ion-color-primary-shade: #e07e28;
  --ion-color-primary-tint: #fe9a43;

  --ion-color-secondary: #364051;
  --ion-color-secondary-rgb: 54, 64, 81;
  --ion-color-secondary-contrast: #364051;
  --ion-color-secondary-contrast-rgb: 54, 64, 81;
  --ion-color-secondary-shade: #303847;
  --ion-color-secondary-tint: #4a5362;

  --ion-color-tertiary: #2e9efe;
  --ion-color-tertiary-rgb: 46, 158, 254;
  --ion-color-tertiary-contrast: #2e9efe;
  --ion-color-tertiary-contrast-rgb: 46, 158, 254;
  --ion-color-tertiary-shade: #2e9efe;
  --ion-color-tertiary-tint: #46aaff;

  --ion-color-infos: #2e9efe;
  --ion-color-infos-rgb: 46, 158, 254;
  --ion-color-infos-contrast: #2e9efe;
  --ion-color-infos-contrast-rgb: 46, 158, 254;
  --ion-color-infos-shade: #2e9efe;
  --ion-color-infos-tint: #46aaff;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #2dd36f;
  --ion-color-success-contrast-rgb: 45, 211, 111;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #ffc409;
  --ion-color-warning-contrast-rgb: 255, 196, 9;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #eb445a;
  --ion-color-danger-contrast-rgb: 235, 68, 90;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #222428;
  --ion-color-dark-contrast-rgb: 34, 36, 40;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #92949c;
  --ion-color-medium-contrast-rgb: 146, 148, 156;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #f4f5f8;
  --ion-color-light-contrast-rgb: 244, 245, 248;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --base-card-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
}

* {
  color-scheme: light;
}
label {
  color: var(--ion-color-secondary);
}
/* Might solve scroll issues 
ion-content {
  --overflow: hidden;
} */
ion-toolbar {
  --color: var(--ion-color-secondary);
}
h3 {
  color: var(--ion-color-secondary);
  font-size: 1.25rem;
  margin: 0.5rem 0;
  font-weight: 700;
}
ion-toolbar {
  --padding-start: 0;
  --padding-end: 0;
}
.button-solid {
  --color: white;
  --color-activated: white;
  --color-activated: white;
  --color-activated: white;
}
.button-outline {
  --color-activated: white;
  --color-activated: white;
  --color-activated: white;
}
