.new-customer-modal {
  padding: 1rem;
  overflow-y: scroll;
}
.new-customer-modal__form {
  margin-bottom: 1rem;
}
.new-customer-modal__form__names {
  display: flex;
  justify-content: space-between;
}
.new-customer-modal__form__names__spacer {
  width: 1rem;
}
