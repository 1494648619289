.products-category-color-item {
  position: relative;
  height: 72px;
  width: 72px;
  border-radius: 0.5rem;
}
.products-category-color-item__selected-dot {
  position: absolute;
  height: 16px;
  width: 16px;
  background-color: var(--ion-color-primary);
  border: 2px solid white;
  content: " ";
  border-radius: 100%;
  right: 0;
}
