.signin-logo {
  width: 100%;
  max-width: 24rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 4rem;
}
.signin-form {
  width: 100%;
  height: 100%;
  max-width: 24rem;
}

/*
////// LEGACY CSS
*/

/* .signin-logo {
  width: 100%;
  max-width: 24rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15% 0;
} */
/* @media screen and (min-height: 600px) {
  .signin-logo {
    padding-top: 25%;
  }
}
@media screen and (min-height: 720px) {
  .signin-logo {
    padding-top: 50%;
  }
}
@media screen and (min-width: 640px) {
  .signin-logo {
    padding: 3rem 0;
  }
} */
