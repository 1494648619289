.new-order-page__controls {
  width: 100%;
  display: flex;
}
.new-order-page__controls__button-container {
  width: 50%;
}
.new-order-page__controls__button-container:first-of-type {
  padding-right: 0.5rem;
}
.new-order-page__controls__button-container:last-of-type {
  padding-left: 0.5rem;
}
@media screen and (min-width: 1024px) {
  .new-order-page__controls {
    justify-content: space-between;
  }
  .new-order-page__controls__button-container {
    width: 150px;
  }
}
